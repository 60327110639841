import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import the autoTable plugin

export const ListingLayoutMixins = {
  data() {
    return {
      editModal: false,
      deleteModal: false,
      selectedRow: {},
      pdfLogo: require("@/assets/brandLogo/pdfLogo.png"),

      //
      requestGroups: new Map(),
    };
  },
  computed: {
    options() {
      return { ...this.filters, ...this.subFilters };
    },
    //

    // Permissions

    isView() {
      const val = this.permissions.view;
      return val ? this.$admin.can(val) : false;
    },
    isCreate() {
      const val = this.permissions.create;
      return val ? this.$admin.can(val) : false;
    },
    isEdit() {
      const val = this.permissions.edit;
      return val ? this.$admin.can(val) : false;
    },
    isDelete() {
      const val = this.permissions.delete;
      return val ? this.$admin.can(val) : false;
    },
    // Permissions
  },
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    onFiltersChange(val) {
      this.filters = val;
      this.subFilters = {};
      if (!this.authUser.isAdmin && this.authUser.user_nature != "fleet") {
        this.filters.tenant_id = this.authUser.tenant_id;
      }
    },

    // =================
    // FOr CRUD Action
    editItem(item) {
      this.selectedRow = { ...item };
      this.toggleEditModal();
    },
    toggleEditModal() {
      return (this.editModal = !this.editModal);
    },
    // Delete Row Section
    // // when delete icon Select
    deleteItem(item) {
      this.selectedRow = { ...item };
      this.toggleDeleteModal("show");
    },
    // // Funtion Invoke When modal Show , Close or Ok
    toggleDeleteModal(v) {
      if (v == "close" || v == "show") {
        return (this.deleteModal = !this.deleteModal);
      } else if (v == "ok") {
        this.deleteSelectedRow(this.selectedRow);
      }
    },
    // Delete Row Section

    // File Create and Download
    convertToCSV(head, objArray) {
      let headValue = head.map((r) => r.value);
      let headText = head.map((r) => r.text).join(",") + "\r\n";
      const arr = [];
      objArray.forEach((el) => {
        arr.push(
          headValue.map((r) => {
            return typeof el[r] === "string" ? el[r].replace(/,/g, "") : el[r];
          })
        );
      });
      let csvContent = arr.join("\r\n");

      return headText + csvContent;
    },
    downloadCSV(head, rows, fName = "logFile") {
      const csv = this.convertToCSV(head, rows);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${fName}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    downloadExcel(head, rows, fName = "logFile") {
      const arr = [];
      rows.forEach((r) => {
        const prm = {};
        head.forEach((h) => {
          prm[h.text] = r[h.value];
        });
        arr.push(prm);
      });
      // Convert data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(arr);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Write the workbook and convert to a binary string
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Convert the buffer to a Blob and trigger the download
      const data = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(data, `${fName}.xlsx`);
    },
    downloadPDF(head, rows, fName = "logFile") {
      const doc = new jsPDF({
        orientation: "landscape",
      });

      // Add the logo (Base64 image)
      const img = this.pdfLogo; // Vue will resolve it to Base64 or image path
      doc.addImage(img, "PNG", 10, 10, 50, 20); // (image, format, x, y, width, height)

      doc.setFontSize(18);
      doc.text(`${fName}`, 14, 40);

      const headValue = head.map((r) => r.value);
      const headers = [head.map((r) => r.text)];

      const arr = [];
      rows.forEach((el) => {
        arr.push(headValue.map((r) => el[r]));
      });

      doc.autoTable({
        head: headers,
        body: arr,
        startY: 30,
      });

      doc.save(`${fName}.pdf`);
    },

    // Cancellation request
    // Function to cancel all requests in a specific group
    cancelRequestGroup() {
      if (this.requestGroups.has(this.groupId)) {
        const controllers = this.requestGroups.get(this.groupId);
        controllers.forEach((controller) => controller.abort());
        this.requestGroups.delete(this.groupId); // Clear the group after cancellation
      }
    },

    // Function to track a new request in a specific group
    trackRequest() {
      const controller = new AbortController();
      if (!this.requestGroups.has(this.groupId)) {
        this.requestGroups.set(this.groupId, []);
      }
      this.requestGroups.get(this.groupId).push(controller);
      return controller;
    },
  },
};
